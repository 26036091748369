const EMAIL_ID = "email";
const NOM_ID = "nom";
const MESSAGE_ID = "message";

sendMail = (event) => {
    event.preventDefault();
    if (!controlForm()) {
        return false;
    }

    const message = `Email : ${getValue(EMAIL_ID)}
    Message : ${getValue(MESSAGE_ID)}`;

    const mail = {
        "mailKey": "VnFVP8bZbZ",
        "nom": getValue(NOM_ID),
        "email": "servicedebarraslyon@gmail.com",
        "message": message
    };

    fetch('https://mailer.majidance.com/mailer', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(mail),
    }).then(() => {
        clearValue(NOM_ID);
        clearValue(EMAIL_ID);
        clearValue(MESSAGE_ID);
    });

    return true;
}

controlForm = () => {
    let valid = true;
    valid = isMailValid() && valid;
    valid = isMessageValid() && valid;
    valid = isNomValid() && valid;

    return valid;
}

isMailValid = () => {
    const mail = document.getElementById(EMAIL_ID);

    const invalid = !mail.value || !mail.value.trim() || !validateEmail(mail.value);
    setErrorField(mail, invalid);
    return !invalid;
}

isMessageValid = () => {
    const message = document.getElementById(MESSAGE_ID);

    const invalid = !message.value || !message.value.trim();
    setErrorField(message, invalid);
    return !invalid;
}

isNomValid = () => {
    const nom = document.getElementById(NOM_ID);

    const invalid = !nom.value || !nom.value.trim();
    setErrorField(nom, invalid);
    return !invalid;
}

setErrorField = (field, inError) => {
    inError ? field.classList.add('error') : field.classList.remove('error');
}

validateEmail = (mail) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return true
    }
    return false
}

getValue = (fieldId) => {
    return document.getElementById(fieldId).value;
}

clearValue = (fieldId) => {
    document.getElementById(fieldId).value = '';
}